<template>
  <div class="login-access-redirect">
    <img
      :src="pngLogo"
      class="logo"
      width="200"
    />
    <b-card
      tag="article"
      class="login-access-redirect-card mb-2"
    >
      <b-card-text>
        <p id="title"><i class="bi bi-caret-down-fill"></i>スマートフォン版サイトはこちら</p>
        <p id="description">
          Poshspaceはスマートフォンに特化したサイトです。スマートフォンでのご利用は下のQRコードを読み込んでアクセスしてください。
        </p>
      </b-card-text>

      <div>
        <p id="sub-description">スマートフォン版トップページ</p>
      </div>

      <div>
        <img
          class="qr-image"
          :src="qrImageSrc"
        />
      </div>

      <p id="title"><i class="bi bi-caret-down-fill"></i>PC版サイトはこちら</p>
      <a class="pc-site-url" href="https://poshspace.jp/pc/map">https://poshspace.jp/pc/map</a>
      <p class="pc-site-notion">※PC版ではスマートフォン版の一部機能をご利用いただけません。</p>
      <p
       id="company-info-link"
       @click="$_onClickRedirectCompanySite"
      >
        Poshspace運営会社について
      </p>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'AppUserLoginAccessRedirect',
  data: function () {
    return {
      email: '',
      password: '',
      qrImageSrc: '',
      pngLogo: require('@/assets/image/logo/logo-mobile.png')
    }
  },
  mounted: function () {
    this.$store.dispatch('loadingMask/hideLoadingMask')

    if (
      location.href.includes('poshspace.jp') ||
      location.href.includes('posh-a729d')
    ) {
      // production
      this.qrImageSrc = require('@/assets/image/qr/signup_qr.png')
    } else {
      // development
      this.qrImageSrc = require('@/assets/image/qr/signup_qr_dev.png')
    }
  },
  methods: {
    /**
     * TeePees公式サイトへ遷移
     */
    $_onClickRedirectCompanySite: function () {
      window.open('https://www.teepees.co.jp/company', '_blank', 'noreferrer')
    }
  }
}
</script>

<style lang="scss">
/* overwrite */
.card-body {
  padding: 1rem 2rem;
}
.card-text {
  margin-bottom: 1rem;
}
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.login-access-redirect {
  color: #707070;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: #F5F5F5;
  height: 100vh;
  .logo {
    margin: 120px 0px 40px 0;
  }
}
.login-access-redirect-card {
  width: 420px;
  border-radius: 16px;
}
#title {
  margin: 1.5rem 0;
  font-size: 18px;
}
.pc-site-url {
  color: #12b6d4;
  padding-bottom: 1.5rem;
  text-decoration: underline;
  display: inline-block;
}
.pc-site-notion {
  font-size: 10px;
  margin-bottom: 2rem;
}
#description {
  text-align: left;
  font-size: 12px;
  line-height: 24px;
}
#sub-description {
  color: #12B6D4;
  font-size: 12px;
  margin-bottom: 0;
}
.qr-image {
  width: 160px
}
#password {
  margin: 0;
}
input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border: 1px solid #707070;
  border-radius: 4px;
}
#company-info-link {
  margin: 1.5rem;
  text-decoration:none;
  color: #707070;
  font-size: 12px;
  border-bottom: 1px solid #707070;
  display: inline-block;
  cursor: pointer;
}
</style>
