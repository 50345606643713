<template>
  <div class="login-access-redirect">
    <LoginAccessRedirect />
  </div>
</template>

<script>
import LoginAccessRedirect from '@/components/organisms/user/AppUserLoginAccessRedirect'

export default {
  name: 'TheLoginAccessRedirect',
  components: { LoginAccessRedirect },
}
</script>
